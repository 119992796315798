import fb from './facebook.svg'

const FbButton = () => {
    return (
        <a class='epikoinwnia-btn fb' href='https://www.facebook.com/petcenterpatras/'>
            <img src={fb}></img>
            <div>PET Center Patras</div>
        </a>
    )
}

export default FbButton
